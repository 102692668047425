<template>
  <div class="width: 100%">

    <div class="row">
      <div class="col">
        <p>These settings update whenever you make a change.</p>
      </div>
    </div>

    <div class="row">
      <div class="col">

        <div v-for="t in toggles" class="form-group">
          <label class="form-label">{{ t.name }}</label>
          <select class="form-control" v-model="t.value" @change="updateToggle(t)">
            <option value="true">Enabled</option>
            <option value="false">Disabled</option>
          </select>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import axios from 'axios'
import authHeader from '../../services/auth-header';

export default {
  name: 'Toggles',
  data(){
    return {
      toggles: []
    }
  },
  mounted(){
    if(!this.$can('claims-manage-team')){
      this.$router.back();
    }
    this.getToggles()
  },
  methods: {
    getToggles(){
      axios.get(`https://api.varsanpr.com/api/claims/toggles`, {
        headers: authHeader()
      })
      .then(response => {
        this.toggles = response.data
      })
      .catch(error => {
        this.$error("Failed loading toggles", error);
      })
    },
    updateToggle(toggle){
      axios.patch(`https://api.varsanpr.com/api/claims/toggles`, toggle, {
        headers: authHeader()
      })
      .then(response => {
        this.$success("Toggle updated successfully");
      })
      .catch(error => {
        this.$error("Failed updating toggle", error);
      })
    }
  }
}

</script>
<template>
  <div style="width: 100%;">

    <!-- Filters -->
    <div class="row" v-show="!currentSpotCheck">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">

            <h4 class="card-title text-white">
              Spot Check Filters
            </h4>

            <div class="row">

              <div class="col-3">
                <div class="form-group">
                  <label class="form-label">User <span class="text-danger">*</span></label>
                  <select class="form-control form-control-dark" v-model="filters.user_id">
                    <option :value="null">Select User</option>
                    <option v-for="user in users" :value="user.id">{{ user.email }}</option>
                  </select>
                </div>
              </div>

              <div class="col-3">
                <div class="form-group">
                  <label class="form-label">Action Types <span class="text-danger">*</span></label>
                  <select class="form-control form-control-dark" v-model="filters.action_type">
                    <option :value="null">Select Action Type</option>
                    <option>All</option>
                    <option>Cancelled</option>
                    <option>Processed</option>
                    <option>Tampered Plate</option>
                    <option>Awaiting Evidence</option>
                  </select>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">Date Range <span class="text-danger">*</span></label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Start</span>
                    </div>
                    <input type="date" v-model="filters.start_date" class="form-control-dark" placeholder="Start Date">
                    <div class="input-group-middle">
                      <span class="input-group-text">End</span>
                    </div>
                    <input type="date" v-model="filters.end_date" class="form-control-dark" placeholder="End Date">
                  </div><!-- input-group -->
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-12">
                <button class="btn btn-primary float-right" @click="loadSpotChecks(1)">Search</button>
                <button class="btn btn-secondary float-right mr-2" @click="clearFilters">Clear Filters</button>
                <button class="btn btn-warning float-right mr-2" @click="createSpotCheck">New Spot Check</button>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <!-- Spot check table -->
    <div class="row mg-t-20" v-show="!currentSpotCheck">
      <div class="col-12">

        <Pagination :data="pagination" :showCount="true" @search="loadSpotChecks" />

        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th>ID</th>
              <td>Created At</td>
              <th>Spot Checker</th>
              <th>Administrator</th>
              <th>Period</th>
              <th>Action Types</th>
              <th>Completed?</th>
              <th>Issues Found?</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sc in spotChecks">
              <td>{{sc.id}}</td>
              <td>{{sc.created_at}}</td>
              <td>{{sc.user_email}}</td>
              <td>{{sc.target_email}}</td>
              <td>
                {{sc.start_date}} to {{sc.end_date}}
              </td>
              <td>{{sc.action_types}}</td>
              <td>{{sc.completed}}</td>
              <td>{{sc.issues}}</td>
              <td>
                <button class="btn btn-sm btn-primary" @click="selectSpotCheck(sc)">View</button>
                <button class="btn btn-sm btn-danger mg-l-10" v-if="$can('claims-manage-team')" @click="deleteSpotCheck(sc)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>

        <Pagination :data="pagination" :showCount="true" @search="loadSpotChecks" />

      </div>
    </div>

    <!-- Spot Check View -->
    <div class="row" v-if="currentSpotCheck">

      <div class="card bd-0 mg-b-20" style="background: transparent; width: 100%;">
        <div class="card-header bg-primary tx-white">
            <ul class="nav nav-tabs nav-tabs-for-dark card-header-tabs">
                <li v-for="t in tabs" :key="'tab-' + t.name" class="nav-item" :class="{'active': t.showing}">
                    <a class="nav-link text-white bd-0 pd-y-8 ft-left" :class="{'active pd-y-8': t.showing}" href="javascript:void(0);" @click="selectTab(t.name)">{{t.name}}</a>
                    <a v-if="t.name !== 'Search'" class="nav-link text-white bd-0 pd-y-8 ft-right tab-close" style="padding-left: 0; padding-right: 0;" :class="{'active pd-y-8': t.showing}" href="javascript:void(0);" @click="closeTab(t.name)"><i class="fas fa-times"></i></a>
                    <a v-if="t.name !== 'Search'" class="nav-link text-white bd-0 pd-y-8 ft-right tab-reload" style="padding-left: 0; padding-right: 0; margin-left: -1rem;" :class="{'active pd-y-8': t.showing}" href="javascript:void(0);" @click="reloadClaim(t.name)"><i class="fa-solid fa-repeat"></i></a>
                </li>
            </ul>
        </div><!-- card-header -->
        <div class="card-body" style="background: transparent;">
          <claim v-for="tab in claimTabs" :ref="'claim-' + tab.id" v-show="tab.showing" :key="tab.id" :claim_id="parseInt(tab.id)" :client_id="parseInt(tab.client_id)" @open-claim="selectClaim" @triggerNext="triggerNext" :presence="[]"></claim>

          <div v-show="selectedTab.name === 'Search'">
            <button class="btn btn-primary mg-b-20" @click="currentSpotCheck = null; claims = [];">Back</button>

            <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th>Reference</th>
                  <th>Action Type</th>
                  <th>Checked?</th>
                  <th>Issues found?</th>
                  <th>Completed At</th>
                  <th>Notes</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="claim in claims">
                  <td>{{claim.reference}}</td>
                  <td>{{ claim.action_type }}</td>
                  <td>
                    <div class="form-check">
                      <input class="form-check input" @change="claimCompleted(claim)" type="checkbox" v-model="claim.completed">
                    </div>
                  </td>
                  <td>
                    <div class="form-check">
                      <input class="form-check input" @change="claimCompleted(claim)" type="checkbox" v-model="claim.issue">
                    </div>
                  </td>
                  <td>{{claim.completed_at || 'Not Completed'}}</td>
                  <td>
                    <textarea class="form-control" rows="3" v-model="claim.notes"></textarea>
                  </td>
                  <td>
                    <button class="btn btn-sm btn-success mg-r-10" @click="claimCompleted(claim)">Save</button>
                    <button class="btn btn-sm btn-primary" @click="selectClaim({reference: claim.reference, id: claim.claim_id, client_id: claim.client_id})">View Claim</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios';
import authHeader from '../../services/auth-header';
import Pagination from '../../components/Pagination.vue';
import Claim from "../claims/Claim.vue";

export default {
  name: 'spotchecks',
  components: {
    Pagination,
    Claim
  },
  data(){
    return {
      spotChecks: [],
      users: [],
      filters: {
        user_id: null,
        action_type: null,
        start_date: null,
        end_date: null
      },
      pagination: {
        currPage: 1,
        totalResults: 0,
        from: 0,
        to: 0,
        perPage: 25,
        lastPage: 1,
      },
      currentSpotCheck: null,
      claims: [],
      tabs: [
          {
              name: "Search",
              claim: false,
              id: -1,
              showing: true
          }
      ]
    }
  },
  mounted(){
    this.loadUsers();
    this.loadSpotChecks();
  },
  methods: {
    loadUsers(){
      axios.get(`https://api.varsanpr.com/api/users/${this.$store.state.auth.user.id}/children`, {
        headers: authHeader()
      })
      .then(response => {
        this.users = response.data.users;
      })
      .catch(error => {
        this.$error("Failed loading processors!", error);
      });
    },
    loadSpotChecks(page = 1){
      axios.get(`https://api.varsanpr.com/api/claims/spotchecks`, {
        headers: authHeader(),
        params: {
          page: page,
          user_id: this.filters.user_id,
          action_type: this.filters.action_type,
          start_date: this.filters.start_date,
          end_date: this.filters.end_date
        }
      })
      .then(response => {
        this.spotChecks = response.data.data;
        this.spotChecks.forEach((sc) => {
          sc.created_at = new Date(sc.created_at).toLocaleString();
          sc.start_date = new Date(sc.start_date).toLocaleDateString();
          sc.end_date = new Date(sc.end_date).toLocaleDateString();
          sc.updated_at = new Date(sc.updated_at).toLocaleString();
        });
        this.pagination.currPage = response.data.page;
        this.pagination.totalResults = response.data.total;
        this.pagination.from = response.data.page * response.data.count - response.data.count + 1;
        this.pagination.to = response.data.page * response.data.count;
        this.pagination.perPage = response.data.count;
        this.pagination.lastPage = response.data.maxPage;
      })
      .catch(error => {
        this.$error("Failed loading spot checks!", error);
      });
    },
    clearFilters(){
      this.filters = {
        user_id: null,
        action_type: null,
        start_date: null,
        end_date: null
      };
    },
    createSpotCheck(){
      let confirmation = confirm("This will create a new spot check using the search filters you have configured, do you wish to continue?");

      if(!confirmation){
        return;
      }

      // Check we have the required fields
      if(!this.filters.user_id || !this.filters.start_date || !this.filters.end_date){
        this.$error("Please ensure you have selected a user and a start and end date for the spot check.");
        return;
      }

      // Create a spot check
      axios.post(`https://api.varsanpr.com/api/claims/spotchecks`, {
        target_user_id: this.filters.user_id,
        start_date: this.filters.start_date,
        end_date: this.filters.end_date,
        category: this.filters.action_type
      }, {
        headers: authHeader()
      })
      .then(response => {
        this.$success("Spot check created successfully!");
        this.clearFilters();
        this.loadSpotChecks();
      })
      .catch(error => {
        this.$error("Failed creating spot check!", error);
      });
    },
    selectSpotCheck(sc){
      this.currentSpotCheck = sc;
      axios.get(`https://api.varsanpr.com/api/claims/spotchecks/${sc.id}`, {
        headers: authHeader()
      })
      .then(response => {
        this.claims = response.data.claims;
        this.claims.forEach((claim) => {
          if(claim.completed_at){
            claim.completed_at = new Date(claim.completed_at).toLocaleString();
          }
          claim.completed = claim.completed === 1;
          claim.issue = claim.issue === 1;
        })
      })
      .catch(error => {
        this.$error("Failed loading spot check claims!", error);
      });
    },
    claimCompleted(claim) {
      claim.completed_at = claim.completed_at ? claim.completed_at : new Date().toLocaleString();
      axios.patch(`https://api.varsanpr.com/api/claims/spotchecks/${claim.id}`, {
        completed: claim.completed_at ? 1 : 0,
        issue: claim.issue ? 1 : 0,
        notes: claim.notes
      }, {
        headers: authHeader()
      })
      .then(response => {
        this.$success("Claim updated successfully!");
        let sc = this.spotChecks.filter(sc => {
          return sc.id === this.currentSpotCheck.id;
        })[0];

        if(sc){
          sc.issues = this.claims.filter(c => {
            return c.issue;
          }).length;
          sc.completed = this.claims.filter(c => {
            return c.completed;
          }).length;
        }
      })
      .catch(error => {
        this.$error("Failed updating claim!", error);
      });
    },
    selectTab(t){
        let findTab = this.tabs.filter(tab => {
            return tab.name === t;
        })[0];

        if(!findTab) return;

        this.tabs.forEach(tab => {
            tab.showing = false;
        });

        findTab.showing = true;

    },
    reloadClaim(t){
        let findTab = this.tabs.filter(tab => {
            return tab.name === t;
        })[0];

        if(!findTab) return;

        let component = this.$refs[`claim-${findTab.id}`][0];
        component.loadClaim(true);
    },
    async closeTab(t){
        let findTab = this.tabs.filter(tab => {
            return tab.name === t;
        })[0];

        if(!findTab) return;

        let component = this.$refs[`claim-${findTab.id}`][0];
        console.log(component);

        let canClose = await component.triggerClose();

        if(!canClose) return;

        if(findTab.showing){
            this.tabs.forEach(tab => {
                tab.showing = tab.name === 'Search';
            });
        }

        // this.socket.sendToGroup('claims', {"event": "close-claim", "content": {
        //     "reference": findTab.name,
        //     "user": {
        //         "id": this.$store.state.auth.user.id,
        //         "email": this.$store.state.auth.user.email
        //     }
        // }});

        this.tabs = this.tabs.filter(tab => {
            return tab.id !== findTab.id;
        });

        this.searchClaims(this.pagination.currPage);

        if(!this.$store.state.showClientSelector && this.tabs.length < 2){
            this.$store.commit('toggleClientSelector');
        }
    },
    selectClaim(claim){
        // $router.push(`/claims/management/${claim.id}`);
        let existingCheck = this.tabs.filter(t => {
            return t.id == claim.id;
        })[0];
        if(existingCheck){
            this.tabs.forEach(t => {
                t.showing = t.id === claim.id;
            });
            return;
        }
        this.tabs.forEach(t => {
            t.showing = false;
        });
        this.tabs.push({
            name: claim.reference,
            id: claim.id,
            client_id: claim.client_id,
            claim: true,
            showing: true
        });
        // this.socket.sendToGroup('claims', {"event": "open-claim", "content": {
        //     "reference": claim.reference,
        //     "user": {
        //         "id": this.$store.state.auth.user.id,
        //         "email": this.$store.state.auth.user.email
        //     }
        // }});

        if(this.$store.state.showClientSelector && this.tabs.length > 1){
            this.$store.commit('toggleClientSelector');
        }
    },
    deleteSpotCheck(sc) {

      // confirm
      let confirmation = confirm("Are you sure you want to delete this spot check?");

      if(!confirmation){
        return;
      }

      axios.delete(`https://api.varsanpr.com/api/claims/spotchecks/${sc.id}`, {
        headers: authHeader()
      })
      .then(response => {
        this.$success("Spot check deleted successfully!");
        this.loadSpotChecks();
      })
      .catch(error => {
        this.$error("Failed deleting spot check!", error);
      });

    }
  },
  computed: {
    selectedTab: function(){
        return this.tabs.filter(t => {
            return t.showing;
        })[0];
    },
    claimTabs: function(){
        return this.tabs.filter(tab => {
            return tab.claim == true;
        });
    }
  }
}
</script>

<style scoped>
.card-header-tabs.nav-tabs-for-dark .nav-link.active {
    border-bottom: 2px solid white;
    background-color: transparent;
}

.card-header-tabs.nav-tabs-for-dark .nav-link:hover {
    background-color: transparent;
    border-bottom: 2px solid white;
    font-weight: bold;
}

.tab-close:hover {
    color: red !important;
}

.tab-reload:hover {
    color: lightgreen !important;
}

.presence {
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 100px;
    width: 250px;
    padding: 10px;
    background-color: black;
    opacity: .8;
    color: white;
}

.is-viewed {
    background-color: #b91d287d !important;
}

.nav-item.active {
    border-bottom: 2px solid white;
}

.nav-item.active a {
    border-bottom: none!important;
}
</style>
import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import store from "../store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "",
    component: () => import("../views/layout/LoggedInLayout.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../views/dashboard/Dashboard.vue"),
      },
      {
        path: "globalclaims/:status",
        name: "globalclaims",
        props: true,
        component: () => import("../views/globalclaims/Claims.vue"),
      },
      {
        path: "claims/management",
        name: "claims",
        props: true,
        component: () => import("../views/claims/Claims.vue"),
      },
      {
        path: "/google2fa",
        name: "google2fa",
        component: () => import("../views/auth/GoogleTwoFactor"),
        props: true,
      },
      {
        path: "/twofactor",
        name: "twoFactorAuth",
        component: () => import("../views/auth/Authenticate"),
        props: true,
      },
      {
        path: "/reminders",
        name: "reminders",
        component: () => import("../views/reminders/reminders"),
      },
      {
        path: "/vehiclesearch",
        name: "vehicleSearch",
        component: () => import("../views/vehiclesearch/VehicleSearch"),
      },
      {
        path: "/checker",
        name: "checker",
        component: () => import("../views/checker/Checker"),
      },
      {
        path: "/scanned",
        name: "scanned",
        component: () => import("../views/documents/Documents"),
      },
      {
        path: "/commontransfers",
        name: "commonTransfers",
        component: () => import("../views/commondetails/CommonDetails"),
      },
      {
        path: "/cancellations/admin",
        name: "AdminCancellations",
        component: () => import("../views/cancellations/Admin"),
      },
      {
        path: "/claimlocator",
        name: "ClaimLocator",
        component: () => import("../views/locator/Locator"),
      },
      {
        path: "/createclaim",
        name: "createClaim",
        component: () => import("../views/forms/ClaimForm"),
      },
      {
        path: "/driveOffForm",
        name: "driveOffForm",
        component: () => import("../views/forms/DriveOffForm"),
      },
      {
        path: "/nmopform",
        name: "nmopForm",
        component: () => import("../views/forms/NmopForm"),
      },
      {
        path: "/evidence",
        name: "evidence",
        component: () => import("../views/evidence/Evidence"),
      },
      {
        path: "/evidence/:id",
        name: "evidenceManager",
        component: () => import("../views/evidence/ManageEvidence"),
      },
      {
        path: "/events",
        name: "events",
        component: () => import("../views/events/Events"),
        props: true,
      },
      // {
      //   path: "/pumps",
      //   name: "pumps",
      //   component: () => import("../views/pumps/Pumps"),
      // },
      {
        path: "/spotchecks",
        name: "Spot Checks",
        component: () => import("../views/spotchecks/Spotchecks"),
      },
      {
        path: "/toggles",
        name: "toggles",
        component: () => import("../views/toggles/Toggles"),
      },
      {
        path: "/events/:id",
        name: "event",
        component: () => import("../views/events/Event"),
      },
      {
        path: "/blacklist",
        name: "blacklist",
        component: () => import("../views/blacklist/Blacklist"),
      },
      {
        path: "/blacklist/create",
        name: "createBlacklist",
        component: () => import("../views/blacklist/Create"),
      },
      {
        path: "/reports",
        name: "reports",
        component: () => import("../views/reports/Reports"),
        props: true,
      },
      {
        path: "/calls",
        name: "calls",
        component: () => import("../views/calls/Calls"),
      },
      {
        path: "/vehicles/:registration",
        name: "vehicle",
        component: () => import("../views/vehicle/Vehicle"),
        props: true,
      },
      {
        path: "/complaints",
        name: "complaints",
        component: () => import("../views/complaints/Complaints"),
        props: true,
      },
      {
        path: "/popla",
        name: "popla",
        component: () => import("../views/popla/Popla"),
        props: true,
      },
      {
        path: "/vehiclesar",
        name: "vehiclesar",
        component: () => import("../views/vehiclesar/VehicleSar"),
        props: true,
      },
      {
        path: "/templates/appeals",
        name: "appealtemplates",
        component: () => import("../views/templates/Templates"),
        props: true,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !store.state.auth.status.loggedIn) {
    console.info("Not logged in and not loading login page, redirecting!");
    return next({ name: "Login" });
  }

  next();
});

export default router;

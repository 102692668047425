<template>
    <div>

        <div class="card bd-0 mg-b-20" style="background: transparent;">
            <div class="card-header bg-primary tx-white">
                <ul class="nav nav-tabs nav-tabs-for-dark card-header-tabs">
                    <li v-for="t in tabs" :key="'tab-' + t.name" class="nav-item">
                        <a class="nav-link text-white bd-0 pd-y-8 ft-left" :class="{'active pd-y-8': t.showing}" href="javascript:void(0);" @click="selectTab(t.name)">{{t.name}}</a>
                        <a v-if="t.name !== 'Search'" class="nav-link text-white bd-0 pd-y-8 ft-right tab-close" style="padding-left: 0; padding-right: 0; margin-left: -1rem;" :class="{'active pd-y-8': t.showing}" href="javascript:void(0);" @click="closeTab(t.name)"><i class="fas fa-times"></i></a>
                    </li>
                </ul>
            </div><!-- card-header -->
            <div class="card-body" style="background: transparent;">
                <div v-show="selectedTab.name === 'Search'">
                    <div class="row">
                        <div class="col-12">
                            <pagination :showCount="true" :data="pagination" @search="updatePagination"></pagination>
                        </div>
                    </div>
                    <div class="row">
                        <div class="mg-t-20 col-12">
                            <table v-show="!searching" width="100%" class="table table-striped table-hover tx-white">
                                <thead class="thead-colored thead-primary">
                                <tr>
                                    <th>#</th>
                                    <th>Type</th>
                                    <th>Plate</th>
                                    <th>Reference</th>
                                    <th>Notes</th>
                                    <th>Progress Message</th>
                                    <th>Status</th>
                                    <th>Created</th>
                                    <th>Incident Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="claim in paginatedClaims" style="cursor: pointer" :key="'claim-' + claim.id" @click="selectClaim(claim)">
                                    <td>{{claim.id}}</td>
                                    <td>{{claim.type}} <span v-if="claim.escalated">(No Means of Payment)</span></td>
                                    <td>{{claim.registration}}</td>
                                    <td>
                                        {{claim.reference}}
                                        <span v-show="claim.presence.length > 0" class="text-info">
                                            ({{ claim.presence.length }} {{ claim.presence.length > 1 ? 'viewers' : 'viewer' }})
                                        </span>
                                    </td>
                                    <td><span v-if="claim.note_count > 0" class="badge badge-primary">{{claim.note_count}}</span></td>
                                    <td v-if="(claim.type === 'parking' || claim.type === 'evparking') && claim.status === 'KADOE Error'">
                                        {{claim.post_failure_reason}}
                                    </td>
                                    <td v-else>{{claim.progress_message}}</td>
                                    <td :class="{'text-success': claim.status === 'Debt Collection Review' && claim.dc_verified == 1}">{{claim.status}}</td>
                                    <td>{{claim.date_received}}</td>
                                    <td>
                                        {{ claim.incident_timestamp || '-' }}
                                        <span v-if="claim.incident_timestamp">({{ claim.days_since_incident.toFixed(0) }} days ago)</span>
                                    </td>
                                </tr>
                                <tr v-if="claims.length < 1">
                                    <td colspan="9" class="tx-center">No claims were found that match your search criteria.</td>
                                </tr>
                                </tbody>
                            </table>
                            <loader :show="searching"></loader>
                        </div>
                    </div>
                </div>
                <claim :ref="'claim-' + tab.id" v-for="tab in claimTabs" v-show="tab.showing" :key="tab.id" :claim_id="parseInt(tab.id)" :client_id="parseInt(tab.client_id)" :presence="presence.users" @open-claim="selectClaim"></claim>
            </div>
        </div>

    </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import Loader from '@/components/Loader.vue';
import Claim from '@/views/claims/Claim.vue';
import authHeader from '@/services/auth-header.js';
import {DateTime} from 'luxon';
import axios from 'axios';
import Socket from '@/services/socket.js';

export default {
    name: 'Claims',
    components: {
        Pagination,
        Loader,
        Claim
    },
    data(){
        return {
            status: "Pending",
            pagination: {
                currPage: 1,
                totalResults: 0,
                from: 0,
                to: 0,
                perPage: 20,
                lastPage: 1,
            },
            counts: {
                driveoff: 0,
                nmop: 0,
                parking: 0,
                actionable: 0,
                waiting: 0,
                nonactionable: 0
            },
            claims: [],
            tabs: [
                {
                    name: "Search",
                    claim: false,
                    id: -1,
                    showing: true
                }
            ],
            searching: false,
            socket: null,
            presence: {
                users: [],
                interval: null
            },
        }
    },
    created(){
        this.socket = new Socket(this.$store.state.auth.user.ws_url);
    },  
    unmounted(){
        this.socket.off('open-claim', (message) => {
            this.presence.users.push(message.content);
        });
        this.socket.off('close-claim', (message) => {
            this.presence.users = this.presence.users.filter(u => {
                return !(
                    u.reference == message.reference &&
                    u.user.id == message.content.user.id
                );
            });
        });
        clearInterval(this.presence.interval);
    },  
    async beforeRouteLeave(to, from, next){
        let claimTabs = this.tabs.filter(tab => {
            return tab.claim == true;
        });

        if(claimTabs.length > 0){
            console.log(`Found ${claimTabs.length} claim tabs`);
            for await (let tab of claimTabs){
                console.log(`Checking tab ${tab.id}`);
                let component = this.$refs[`claim-${tab.id}`][0];
                let canClose = await component.triggerClose();
                console.log(`Tab ${tab.id} can close: ${canClose}`);
                if(!canClose){
                    next(false);
                    return;
                }else{
                    if(tab.showing){
                        this.tabs.forEach(t => {
                            t.showing = t.name === 'Search';
                        });
                    }

                    this.socket.sendToGroup('claims', {"event": "close-claim", "content": {
                        "reference": tab.name,
                        "user": {
                            "id": this.$store.state.auth.user.id,
                            "email": this.$store.state.auth.user.email
                        }
                    }});

                    this.tabs = this.tabs.filter(t => {
                        return t.id !== tab.id;
                    });
                }
            }
        }

        if(!this.$store.state.showClientSelector){
            this.$store.commit('toggleClientSelector');
        }

        next();
    },
    mounted(){
        this.status = this.$route.params.status;
        this.socket.on('open-claim', (message) => {
            message._ts = Date.now();
            if(this.presence.users.filter(u => {
                return u.reference == message.reference &&
                    u.user.id == message.user.id
            }).length > 0){
                this.presence.users.filter(u => {
                    return u.reference == message.reference &&
                        u.user.id == message.user.id
                })[0]._ts = Date.now();
                return;
            }
            this.presence.users.push(message);
            this.claims.forEach((claim) => {
                if(claim.reference == message.reference){
                    if(claim.presence.filter((u) => {
                        return u.reference == message.reference &&
                            u.user.id == message.user.id
                    }).length < 1){
                        claim.presence.push(message);
                    }
                }
            });
        });
        this.socket.on('close-claim', (message) => {
            this.presence.users = this.presence.users.filter(u => {
                return !(
                    u.reference == message.reference &&
                    u.user.id == message.user.id
                );
            });
            this.claims.forEach((claim) => {
                if(claim.reference == message.reference){
                    claim.presence = claim.presence.filter((u) => {
                        return !(
                            u.reference == message.reference &&
                            u.user.id == message.user.id
                        );
                    });
                }
            });
        });
        this.presence.interval = setInterval(() => {
            this.tabs.forEach((tab) => {
                if(tab.name !== 'Search'){
                    this.socket.sendToGroup('claims', {"event": "open-claim", "content": {
                        "reference": tab.name,
                        "user": {
                            "id": this.$store.state.auth.user.id,
                            "email": this.$store.state.auth.user.email
                        }
                    }});
                }
            });

            this.presence.users = this.presence.users.filter((u) => {
                return Date.now() - u._ts < 15000;
            });
        }, 5000);

        this.searchClaims();
    },
    methods: {
        updatePagination(page = 1){
            if(parseInt(page) == parseInt(this.pagination.currPage)){
                this.searchClaims();
                return;
            }
            this.pagination.currPage = page;
            this.pagination.from = this.pagination.currPage * this.pagination.perPage - this.pagination.perPage + 1;
            this.pagination.to = this.pagination.currPage * this.pagination.perPage;
            this.pagination.lastPage = Math.ceil(this.pagination.totalResults / this.pagination.perPage);
        },
        selectTab(t){
            let findTab = this.tabs.filter(tab => {
                return tab.name === t;
            })[0];

            if(!findTab) return;

            this.tabs.forEach(tab => {
                tab.showing = false;
            });

            findTab.showing = true;

        },
        async closeTab(t){
            let findTab = this.tabs.filter(tab => {
                return tab.name === t;
            })[0];

            if(!findTab) return;

            let component = this.$refs[`claim-${findTab.id}`][0];
            console.log(component);

            let canClose = await component.triggerClose();

            if(!canClose) return;

            if(findTab.showing){
                this.tabs.forEach(tab => {
                    tab.showing = tab.name === 'Search';
                });
            }

            this.socket.sendToGroup('claims', {"event": "close-claim", "content": {
                "reference": findTab.name,
                "user": {
                    "id": this.$store.state.auth.user.id,
                    "email": this.$store.state.auth.user.email
                }
            }});

            this.tabs = this.tabs.filter(tab => {
                return tab.id !== findTab.id;
            });

            this.claims = this.claims.filter(claim => {
                return claim.id !== findTab.id;
            });

            if(!this.$store.state.showClientSelector && this.tabs.length < 2){
                this.$store.commit('toggleClientSelector');
            }
        },
        selectClaim(claim){
            let existingCheck = this.tabs.filter(t => {
                return t.id == claim.id;
            })[0];
            if(existingCheck){
                this.tabs.forEach(t => {
                    t.showing = t.id === claim.id;
                });
                return;
            }
            this.tabs.forEach(t => {
                t.showing = false;
            });
            this.tabs.push({
                name: claim.reference,
                id: claim.id,
                claim: true,
                client_id: claim.client_id,
                showing: true
            });
            this.socket.sendToGroup('claims', {"event": "open-claim", "content": {
                "reference": claim.reference,
                "user": {
                    "id": this.$store.state.auth.user.id,
                    "email": this.$store.state.auth.user.email
                }
            }});
            if(this.$store.state.showClientSelector && this.tabs.length > 1){
                this.$store.commit('toggleClientSelector');
            }
        },
        searchClaims(){

            if(this.status == 'MyLocked') {

                // We can use sessionStorage to obtain the claims that are locked by the current user
                let lockedClaims = JSON.parse(sessionStorage.getItem('vt::lockedClaims'));
                console.log(lockedClaims);
                // Check it is an array
                lockedClaims = Array.isArray(lockedClaims) ? lockedClaims : [];

                lockedClaims.forEach((claim) => {
                    claim.date_received = DateTime.fromISO(claim.date_received).toFormat("dd/MM/yyyy")
                    claim.escalated = claim.type === 'escalated';
                        switch(claim.type){
                            case 'driveoff':
                            case 'escalated':
                            case 'cnafe':
                                claim.type = 'Drive Off';
                                break;
                            case 'nmop':
                                claim.type = 'No Means of Payment';
                                break;
                            case 'parking':
                                claim.type = 'Parking';
                                break;
                            case 'evparking':
                                claim.type = 'EV Parking';
                                break;
                            case 'cnaf':
                                claim.type = 'Customer Not at Fault';
                                break;
                        }
                    claim.presence = this.presence.users.filter(u => {
                            return u.reference == claim.reference;
                        }) || [];
                });

                this.pagination.totalResults = lockedClaims.length;
                this.pagination.from = this.pagination.currPage * this.pagination.perPage - this.pagination.perPage + 1;
                this.pagination.to = this.pagination.currPage * this.pagination.perPage;
                this.pagination.lastPage = Math.ceil(this.pagination.totalResults / this.pagination.perPage);

                this.claims = lockedClaims;

                return;

            }

            let url = `https://api.varsanpr.com/api/claims/global/${this.status}`;

            this.searching = true;

            axios.get(url, {
                headers: authHeader()
            })
                .then(response => {
                    response.data.claims.forEach((claim) => {
                        claim.date_received = DateTime.fromSeconds(parseInt(claim.date_received)).toFormat("dd/MM/yyyy")
                        claim.escalated = claim.type === 'escalated';
                        switch(claim.type){
                            case 'driveoff':
                            case 'escalated':
                            case 'cnafe':
                                claim.type = 'Drive Off';
                                break;
                            case 'nmop':
                                claim.type = 'No Means of Payment';
                                break;
                            case 'parking':
                                claim.type = 'Parking';
                                break;
                            case 'evparking':
                                claim.type = 'EV Parking';
                                break;
                            case 'cnaf':
                                claim.type = 'Customer Not at Fault';
                                break;
                        }
                        claim.days_since_incident = null;
                        if(claim.incident_timestamp){
                            claim.days_since_incident = Math.abs(DateTime.fromSeconds(parseInt(claim.incident_timestamp)).diffNow('days').toObject().days.toFixed(2));
                            claim.incident_timestamp = DateTime.fromSeconds(parseInt(claim.incident_timestamp)).toFormat("dd/MM/yyyy");
                        }
                        claim.presence = this.presence.users.filter(u => {
                            return u.reference == claim.reference;
                        }) || [];
                    })
                    this.claims = response.data.claims;
                    this.pagination.totalResults = response.data.count;
                    this.pagination.from = this.pagination.currPage * this.pagination.perPage - this.pagination.perPage + 1;
                    this.pagination.to = this.pagination.currPage * this.pagination.perPage;
                    this.pagination.lastPage = Math.ceil(this.pagination.totalResults / this.pagination.perPage);
                })
                .catch(error => {
                    this.$error("Unable to load claims", error);
                })
            .finally(() => {
                this.searching = false;
            });
        },
        qc(val){
            if(val === null) return false;
            if(val === undefined) return false;

            if(typeof val === 'string'){
                if(val.length < 1) return false;
            }

            return true;
        },
    },
    computed: {
        selectedTab: function(){
            return this.tabs.filter(t => {
                return t.showing;
            })[0];
        },
        claimTabs: function(){
            return this.tabs.filter(tab => {
                return tab.claim == true;
            });
        },
        paginatedClaims: function(){
            return this.claims.slice(this.pagination.currPage * this.pagination.perPage - this.pagination.perPage, this.pagination.currPage * this.pagination.perPage);
        },
    }
}
</script>

<style scoped>
.card-header-tabs.nav-tabs-for-dark .nav-link.active {
    border-bottom: 2px solid white;
    background-color: transparent;
}

.card-header-tabs.nav-tabs-for-dark .nav-link:hover {
    background-color: transparent;
    border-bottom: 2px solid white;
    font-weight: bold;
}

.tab-close:hover {
    color: red !important;
}

.presence {
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 100px;
    width: 250px;
    padding: 10px;
    background-color: black;
    opacity: .8;
    color: white;
}

.is-viewed {
    background-color: #b91d287d !important;
}
</style>
